import { axios, API_ROUTES } from "@/config";

export default {
  async getAll(id, data) {
    let result = await axios.post(
      `${API_ROUTES.categoryEdit.save}/${id}`,
      data
    );

    return result;
  },

  async stepGroup(data) {
    let result = await axios.post(`${API_ROUTES.stepGroupEdit.save}`, data);

    return result;
  },

  async stepAttributes(data) {
    let result = await axios.post(
      `${API_ROUTES.stepAttributesEdit.save}`,
      data
    );

    return result;
  },

  async getMultimedia(data) {
    let result = await axios.post(`${API_ROUTES.multimediaEdit.save}`, data);

    return result;
  },
};
